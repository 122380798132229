<template>
    <div>

    </div>
</template>

<script>
export default {
  name: 'Update',

  data () {
    return {

    };
  },

  mounted () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.search('aplayzNativeAos') < 0) {
      location.href = `https://play.google.com/store/apps/details?id=com.mobileaos.aplayz`;
    }
  },

  methods: {

  }
};
</script>

<style scoped></style>
